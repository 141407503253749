<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${serviceCenter.name}` }} -
        {{ $t("COMMON.SERVICECENTER") }}
      </span>
    </div>

    <establishment-list-table :filterSubject="serviceCenter" />
  </div>
</template>
<script>
import EstablishmentListTable from "../../EstablishmentManagement/partials/EstablishmentListTable.vue";

export default {
  name: "service-center-view-establishments",

  components: { EstablishmentListTable },

  props: ["serviceCenter"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {
    addEstablishmentCafeteras() {
      this.showEstablishmentAddModal = true;
    },

    onCloseModal() {
      this.showEstablishmentAddModal = false;
      this.$emit("serviceCenterUpdated", true);
      this.rerenderKey++;
    },
  },

  mounted() {},

  watch: {
    serviceCenter(serviceCenter) {},
  },
};
</script>
